import React from 'react';
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IExternalLinkProps {
    link: string;
    text: string;
    color?: string;
    icon?: IconProp;
    image?: string;
}

const ExternalLink = (props: IExternalLinkProps) => {
    if (props.icon !== undefined) {
        return (
            <>
                <FontAwesomeIcon color={props.color} fixedWidth size={"2x"} icon={props.icon}/> {" "}
                <a href={props.link} target={"_blank"}>{props.text}</a>
            </>
        );
    } else {
        return (
            <>
                <img className={"logo_image"} src={props.image} alt={props.text}/> {" "}
                <a href={props.link} target={"_blank"}>{props.text}</a>
            </>
        );
    }
};

export default ExternalLink;