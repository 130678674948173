import React from 'react';
import './Bio.css';
import {faFacebook, faGithub, faJava, faJsSquare, faLinkedin, faPython} from "@fortawesome/free-brands-svg-icons";
import {faAt, faFlask, faInfinity, faPeopleCarry, faSitemap, faUserCheck} from "@fortawesome/free-solid-svg-icons";

import Interest from "./Interest";
import ExternalLink from "./ExternalLink";

const createLink = (link: string, text: string): JSX.Element =>
    <a target={"_blank"} href={link}>{text}</a>;

const Bouvet_url = createLink("https://bouvet.no", "Bouvet");
const KDA_url = createLink("https://www.kongsberg.com/kda/", "Kongsberg Defence & Aerospace");
const NTNU_url = createLink("https://www.ntnu.no/studier/mtfyma/industriell_matematikk", "sivilingeniør i industriell matematikk fra NTNU");

const facebook_color = "#3b5998";
const linkedin_color = "#0e76a8";
const github_color = "#171516";

const Bio = () =>
    <div className={"background"}>
        <article className={"bio"}>
            <h2>
                Hei, og velkommen hit!
            </h2>
            <p>
                Jeg er en software-utvikler- og arkitekt som akkurat nå er ansatt i
                konsulentselskapet {Bouvet_url}. Tidligere har jeg jobbet for {KDA_url} og av
                utdanning er jeg {NTNU_url}.
            </p>
            <p>
                Ting jeg bryr meg om og liker å jobbe med:
            </p>
            <ul>
                <li>
                    <Interest text={"Software-arkitektur"} icon={faSitemap}/>
                </li>
                <li><
                    Interest text={"Software-utvikling"} icon={faFlask}/>
                </li>
                <li><
                    Interest text={"Teamledelse"} icon={faPeopleCarry}/>
                </li>
                <li>
                    <Interest text={"Forstå brukerens behov"} icon={faUserCheck}/>
                </li>
                <li>
                    <Interest text={"Java"} icon={faJava}/>
                </li>
                <li>
                    <Interest text={"Javascript"} icon={faJsSquare}/>
                </li>
                <li>
                    <Interest text={"Python"} icon={faPython}/>
                </li>
                <li>
                    <Interest text={"Matematikk"} icon={faInfinity}/>
                </li>
            </ul>
            <p>
                Ellers på Internett kan du blant annet finne meg her:
            </p>
            <ul>
                <li>
                    <ExternalLink
                        link={"https://www.facebook.com/knuterikskare"}
                        text={"Facebook"}
                        icon={faFacebook}
                        color={facebook_color}/>
                </li>
                <li>
                    <ExternalLink
                        link={"https://www.linkedin.com/in/knut-erik-skare"}
                        text={"LinkedIn"}
                        icon={faLinkedin}
                        color={linkedin_color}/>
                </li>
                <li>
                    <ExternalLink
                        link={"mailto:knut.erik.skare@gmail.com"}
                        text={"knut.erik.skare@gmail.com"}
                        icon={faAt}
                        color={"skyblue"}/>
                </li>
            </ul>
            <p>
                Vi snakkes!
            </p>
        </article>
    </div>;

export default Bio;
