import React from 'react';
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IInterestProps {
    text: string;
    icon: IconProp;
}

const Interest = (props: IInterestProps) =>
    <>
        <FontAwesomeIcon color={"skyblue"} fixedWidth size={"2x"} icon={props.icon}/> {" "} {props.text}
    </>;

export default Interest;